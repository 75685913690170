<template>
  <div class="mt-5">
    <b-row class="mt-2">
      <b-col cols="3">
        <div class="bg-white rounded-lg p-4">
          <h4 class="font-weight-bold">Sales Details</h4>
        </div>
      </b-col>
      <b-col cols="9">
        <b-row>
          <b-col cols="12">
            <div class="bg-white rounded-lg p-5">
              <h4>Plot Sizes</h4>
              <b-table
                ref="clientsTable"
                thead-class="bg-gray-400 text-uppercase text-sm-2"
                tbody-class="text-sm"
                :selectable="true"
                hover
                :items="items"
                :fields="fields"
                @row-selected="onRowSelected"
              >
                <template v-slot:cell(check)="{ rowSelected, index }">
                  <div class="d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      :checked="rowSelected"
                      @click="handleRowSelect(rowSelected, index)"
                    />
                  </div>
                </template>
                <template #head(check)="">
                  <input
                    type="checkbox"
                    :checked="selectAll && items.length == selected.length"
                    @click="selectAll = !selectAll"
                  />
                </template>

                <template #cell(status)="data">
                  <div
                    class="text-center px-2 py-1 rounded-lg"
                    :class="
                      data.item.status == 'pending'
                        ? 'bg-warning--light text-warning'
                        : 'text-success bg-success--light'
                    "
                  >
                    <small>{{ data.item.status }}</small>
                  </div>
                </template>

                <template #cell(actions)="">
                  <div class="d-flex">
                    <rm-base-button
                      text="View"
                      class="font-weight-bold"
                      bgColor="primary--light"
                      textColor="primary"
                    ></rm-base-button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="bg-white rounded-lg"></div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectAll: false,
      filterOptions: ["Select Branch", "Select Commissions"],
      selected: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: "check",
          // sortable: true,
          label: "",
        },
        {
          key: "amount_paid",
          // sortable: true,
          label: "Amount Paid",
        },
        {
          key: "payment_mode",
          sortable: false,
          label: "Payment Mode",
        },
        {
          key: "date",
          sortable: false,
          label: "Date",
        },
        {
          key: "bank_account",
          sortable: false,
          label: "Bank Account",
        },
        {
          key: "reference",
          sortable: false,
          label: "Reference",
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
        },
      ],
      items: [
        {
          // isActive: true,
          amount_paid: "N12,000,000",
          payment_mode: "Bank Transfer",
          date: "11/02/2021",
          bank_account: "Fidelity Bank",
          reference: "1200320324",
          status: "completed",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
